@import 'Settings';
@import "@scrippsproduct/entertainment-sites-css/src/base.scss";

body {
    background-color: var(--theme-color-600);
}

.content-constrainer--lead-container {
    padding-block-start: 1.3rem;
}

header {
    padding-block-start: 2rem;

    @media screen and (max-width: 60rem) {
        padding-block-start: 4rem;
    }
}

hr {
    border-color: var(--theme-color-400);

}

p {
    color: var(--mono-color-100);
}

.page-section {
    background-color: transparent;
}

.page-section--light-background {
    background-color: var(--mono-color-100);
}

.image-card__content {
    color: var(--mono-color-100)
}

.main-footer {
    background-image: var(--footer-gradient-overlay), var(--footer-gradient);
}

loading-wrapper {
    --spinner-color: var(--theme-color-400);
}

.page-content-subheader {
    margin-block-end: 2.5rem;
}

error-handler {
    .error-handler__title {
        color: var(--theme-color-200);
    }
}

@media screen and (max-width: 60rem) {
    site-wrapper {
        padding-block-start: 5rem;
    }
}