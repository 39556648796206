@import "@scrippsproduct/entertainment-sites-css/src/header.scss";

.header-divider {
    border-color: var(--mono-color-100);
}

.main-navigation {
    margin: 0 2rem 0 auto;
}

.main-navigation__nav-link:before {
    bottom: unset;
    top: -0.1em;
}

.mobile-nav { height: 5rem; }

.drawer-navigation__nav-bar {
    padding-block-start: 1.1rem;
}

@media screen and (max-width: 60rem){
    .drawer-navigation__primary-navigation {
        background: linear-gradient(to bottom, var(--theme-color-400) 0%, var(--theme-color-600) 100%) !important;
    }
}

.mobile-nav.mobile-nav--shadow {       
    background-image: var(--body-background-url), var(--body-gradient);
    background-blend-mode: var(--body-background-blend-mode);
    background-attachment: var(--body-background-attachment);
}
