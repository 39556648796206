:root {
    /* theme colors */
    --theme-color-100: hsl(18, 100%, 49%);
    --theme-color-200: hsl(32, 100%, 50%); //#FF8B00
    --theme-color-300: hsl(22, 100%, 57%); //#FF7222
    --theme-color-400: hsl(22, 100%, 57%); //#FF7222
    --theme-color-500: hsl(18, 100%, 49%); //#FA4D00
    --theme-color-600: hsl(323, 100%, 9%); //#2E001C
    /* monotones */
    --mono-color-100: #fff;
    --mono-color-200: #0000002B;
    --mono-color-300: hsla(0, 0%, 53%, 0.5);
    --mono-color-400: #666;
    --mono-color-500: #777;
    --mono-color-700: #444;
    --mono-color-800: #222;
    --mono-color-900: #000;
    /* form colors */
    --form-valid-color: hsl(150, 55%, 38%);
    --form-invalid-color: hsl(0, 51%, 50%);
    /* gradients */
    --on-now-gradient: linear-gradient(to bottom, var(--theme-color-100) 0%, var(--theme-color-300) 100%); //linear-gradient(to bottom, var(--form-valid-color) 0%, hsl(150, 54%, 27%) 100%);
    --body-gradient:linear-gradient(to bottom, 
    hsla(57,100%,82%,1) 0%,
    hsla(55,100%,77%,1) 2%,
    hsla(53,100%,76%,1) 3%,
    hsla(37,100%,67%,1) 7%,
    hsla(31,100%,63%,1) 9%,
    hsla(28,98%,60%,1) 12%,
    hsla(26,89%,56%,0.99) 15%,
    hsla(23,87%,56%,0.99) 19%,
    hsla(20,85%,57%,0.98) 23%,
    hsla(11,81%,59%,0.95) 32%,
    hsla(4,73%,58%,0.92) 41%,
    hsla(4,71%,57%,0.91) 43%,
    hsla(2,66%,55%,0.85) 47%,
    hsla(2,58%,50%,0.72) 55%,
    hsla(4,71%,42%,0.54) 66%,
    hsla(6,80%,37%,0.4) 75%,
    hsla(7,88%,33%,0.22) 86%,
    hsla(8,93%,32%,0.02) 99%,
    hsla(8,93%,32%,0) 100%);
    --footer-gradient: linear-gradient(to top, 
    hsla(31,100%,63%,1) 0%,
    hsla(28,98%,60%,1) 3%,
    hsla(26,89%,56%,0.99) 6%,
    hsla(23,87%,56%,0.99) 10%,
    hsla(20,85%,57%,0.98) 14%,
    hsla(11,81%,59%,0.95) 23%,
    hsla(4,73%,58%,0.92) 32%,
    hsla(4,71%,57%,0.91) 34%,
    hsla(2,66%,55%,0.85) 38%,
    hsla(2,58%,50%,0.72) 46%,
    hsla(4,71%,42%,0.54) 57%,
    hsla(6,80%,37%,0.4) 66%,
    hsla(7,88%,33%,0.22) 77%,
    hsla(8,93%,32%,0.02) 90%,
    hsla(8,93%,32%,0) 100%); 
    --footer-gradient-overlay: radial-gradient(ellipse at 50% 72%, transparent 0%, var(--theme-color-600) 100%);
    --menu-gradient: linear-gradient(90deg, hsl(39, 100%, 41%) 0%, hsl(30, 82%, 58%) 50%, hsl(28, 82%, 55%) 90%);
    /* other */
    --cta-button-color: var(--theme-color-200);
    --main-font-family: 'Montserrat', sans-serif;
    --body-background-url: radial-gradient(ellipse at 0% 0%, transparent 44%, hsla(323, 100%, 9%, 86%) 66%); //linear-gradient(150deg, transparent 52%, var(--theme-color-600) 76%);
    --body-background-blend-mode: normal;
    --body-background-repeat: no-repeat;
    --body-background-size: auto 33%, auto 18%;
    --body-background-position: left top;
    --body-background-attachment: scroll;
    --paragraph-font-family: 'Open Sans', sans-serif;
    --anchor-color: var(--theme-color-400);
    --anchor-hover-color: var(--theme-color-600);
    --headers-color: var(--mono-color-100);
    --error-handler-background-color:  var(--theme-color-600);
    --error-handler-outline-color: var(--theme-color-100);
    --modals-close-btn-color: var(--mono-color-100);
    --modals-close-btn-outline-color: var(--theme-color-300);
    --inline-anchor-color: var(--theme-color-400);
}